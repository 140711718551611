import "./player.css"
import { useParams } from "react-router-dom";
import PlayerInfo from "../../components/playerinfo/PlayerInfo";


export default function Player() {
	console.log(useParams().playerId);
	return (
		<div className="player">
			<PlayerInfo/>
		</div>
	)
}