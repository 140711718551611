import "./chart.css"

import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label, other }) => {
	if (active && payload && payload.length) {
		return (
		<div className="custom-tooltip">
			<p className="label">{`${payload[0].value} ${payload[0].name}`}</p>
		</div>
		);
	}

	return null;
};

export default function Chart(data) {
		console.log(data.datakey);
	return (
		<div className="chart">
			<h3 className="chartTitle">{data.title}</h3>
			<ResponsiveContainer width="100%" height="90%">
				<AreaChart data={data.data}>
					<XAxis dataKey="name" textAnchor="start" stroke="white" fontSize="15px" interval={0}/>
					<Area type="monotome" dataKey={data.datakey} stroke={data.color} fill={data.color}/>
					<Tooltip content={<CustomTooltip suffix="Players"/>}/>
					<CartesianGrid stroke="rgba(0,0,0,0.2)" strokeDasharray="3 3"/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	)
}
