import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import Home from "./pages/home/Home";
import "./app.css"
import Server from "./pages/server/Server";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Resources from "./pages/resources/Resources";
import Players from "./pages/players/Players";
import Logs from "./pages/logs/Logs";
import Map from "./pages/map/Map";
import Staff from "./pages/staff/Staff";
import Player from "./pages/player/Player";

function App() {
	return (
		<Router>
			<div>
				<Topbar/>
				<div className="container">
					<Sidebar/>
					<Switch>
						<Route exact path="/home">
							<Home/>
						</Route>
						<Route exact path="/server">
							<Server/>
						</Route>
						<Route exact path="/resources">
							<Resources/>
						</Route>
						<Route exact path="/players">
							<Players/>
						</Route>
						<Route exact path="/player/:playerId">
							<Player/>
						</Route>
						<Route exact path="/logs">
							<Logs/>
						</Route>
						<Route exact path="/map">
							<Map/>
						</Route>
						<Route exact path="/staff">
							<Staff/>
						</Route>
						<Route exact path="/">
							<Home/>
						</Route>
					</Switch>
				</div>
			</div>
		</Router>
	);
}

export default App;
