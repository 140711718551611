import React from 'react'
import "./topbar.css"
import { NotificationsNone, Language, Settings } from '@material-ui/icons';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function Topbar() {
	return (
		<div className="topbar">
			<div className="topbarWrapper">
				<div className="topLeft">
					<a href="/"><span className="logo">Oasis Panel</span></a>
				</div>
				<div className="topRight">
					<div className="topbarIconContainer">
						<NotificationsNone/>
						<span className="topIconBadge">2</span>
					</div>
					<div className="topbarIconContainer">
						<Language/>
					</div>
					<div className="topbarIconContainer">
						<Settings/>
					</div>
					<img src="https://cdn.discordapp.com/attachments/634645008364077057/883596360606175272/Logo-100.jpg" alt="" className="topAvatar" onClick={Settings}/>
				</div>
			</div>
		</div>
	)
}
