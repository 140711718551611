import "./status.css"
import { DriveEtaOutlined, BuildOutlined, LocalHospitalOutlined, GavelOutlined, AttachMoneyOutlined, FolderOpenOutlined, StorageOutlined, GroupOutlined, DescriptionOutlined, MapOutlined, SecurityOutlined } from "@material-ui/icons"

export default function Status(data) {
	if (data.icon === "StorageOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<StorageOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "GroupOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<GroupOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "FolderOpenOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<FolderOpenOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "DescriptionOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<DescriptionOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "MapOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<MapOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "SecurityOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<SecurityOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "AttachMoneyOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<AttachMoneyOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "GavelOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<GavelOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "LocalHospitalOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<LocalHospitalOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "BuildOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<BuildOutlined className="statusIcon"/>
			</div>
		)
	} else if (data.icon === "DriveEtaOutlined") {
		return (
			<div className="status" style={{borderLeft: "5px solid " + data.color}}>
				<h3 className="statusTitle" style={{color: data.color}}>{data.title}</h3>
				<span className="statusContext">{data.content}</span>
				<DriveEtaOutlined className="statusIcon"/>
			</div>
		)
	}
}
