import Chart from "../../components/chart/Chart"
import Serverbar from "../../components/serverbar/Serverbar"
import Status from "../../components/status/Status"
import "./home.css"

const playercount = [
	{
		name: '5:00',
		Players: 3,
	},
	{
		name: '5:10',
		Players: 17,
	},
	{
		name: '5:20',
		Players: 40,
	},
	{
		name: '5:30',
		Players: 62,
	},
	{
		name: '5:40',
		Players: 17,
	},
	{
		name: '5:50',
		Players: 40,
	},
	{
		name: '6:00',
		Players: 62,
	},
];

const economy = [
	{
		name: 'Mon',
		Million: 3,
	},
	{
		name: 'Tue',
		Million: 17,
	},
	{
		name: 'Wed',
		Million: 40,
	},
	{
		name: 'Thur',
		Million: 62,
	},
	{
		name: 'Fri',
		Million: 17,
	},
	{
		name: 'Sat',
		Million: 40,
	},
	{
		name: 'Sun',
		Million: 62,
	},
];

const reports = [
	{
		name: 'Mon',
		Reports: 3,
	},
	{
		name: 'Tue',
		Reports: 17,
	},
	{
		name: 'Wed',
		Reports: 40,
	},
	{
		name: 'Thur',
		Reports: 62,
	},
	{
		name: 'Fri',
		Reports: 17,
	},
	{
		name: 'Sat',
		Reports: 40,
	},
	{
		name: 'Sun',
		Reports: 62,
	},
];

export default function Home() {
	return (
		<div className="home">
			<Serverbar/>
			<div className="statusContainer">
				<Status title="SERVER STATUS" content="Offline" icon="StorageOutlined" color="red"/>
				<Status title="PLAYER COUNT" content="100" icon="GroupOutlined" color="RoyalBlue"/>
				<Status title="ECONOMY" content="$1,000,000" icon="AttachMoneyOutlined" color="yellowgreen"/>
				<Status title="STAFF ONLINE" content="5" icon="SecurityOutlined" color="GoldenRod"/>
			</div>
			<div className="chartContainer">
				<Chart title="Player Count" datakey="Players" data={playercount} color="RoyalBlue"/>
				<Chart title="Economy" datakey="Million" data={economy} color="yellowgreen"/>
				<Chart title="Reports" datakey="Reports" data={reports} color="red"/>
			</div>
		</div>
	)
}
