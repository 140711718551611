import "./resources.css"
import * as React from 'react';
import Button from "@material-ui/core/Button";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@material-ui/data-grid';

const columns = [
	{
		field: 'id',
		headerClassName: 'resourceHeader',
		headerName: 'ID',
		cellClassName: 'resourceHeader',
		width: 90
	},
	{
		field: 'resourcename',
		headerName: 'Resource Name',
		headerClassName: 'resourceHeader',
		cellClassName: 'resourceHeader',
		width: 180,
	},
	{
		field: 'directory',
		headerName: 'Directory',
		headerClassName: 'resourceHeader',
		cellClassName: 'resourceHeader',
		width: 180,
	},
	{
		field: 'ms',
		headerName: 'MS',
		type: 'number',
		headerClassName: 'resourceHeader',
		cellClassName: 'resourceHeader',
		width: 110,
	},
	{
		field: 'status',
		headerName: 'Status',
		cellClassName: 'resourceHeader',
		headerClassName: 'resourceHeader',
		width: 160,
	},
	/*{
		field: "Functions",
		width: 170,
		renderCell: (cellValues) => {
			return (
				<div className="functionbuttons">
					<Button
						variant="contained"
						color="primary"
						onClick={(event) => {
						}}
					>
						Start
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={(event) => {
						}}
					>
						Stop
					</Button>
				</div>
			);
		}
	}*/
];

const rows = [
	{ id: 1, resourcename: 'Snow', directory: 'c:/test/location/snow', ms: 35, status: "Running" },
	{ id: 2, resourcename: 'Lannister', directory: 'Cersei', ms: 42, status: "Running" },
	{ id: 3, resourcename: 'Lannister', directory: 'Jaime', ms: 45, status: "Running" },
	{ id: 4, resourcename: 'Stark', directory: 'Arya', ms: 16, status: "Running" },
	{ id: 5, resourcename: 'Targaryen', directory: 'Daenerys', ms: null, status: "Running" },
	{ id: 6, resourcename: 'Melisandre', directory: null, ms: 150, status: "Running" },
	{ id: 7, resourcename: 'Clifford', directory: 'Ferrara', ms: 44, status: "Running" },
	{ id: 8, resourcename: 'Frances', directory: 'Rossini', ms: 36, status: "Running" },
	{ id: 9, resourcename: 'Roxie', directory: 'Harvey', ms: 65, status: "Running" },
];

export default function Resources() {
	return (
		<div className="resources">
			<DataGrid 
				rows={rows}
				columns={columns}
				pmsSize={5}
				disableSelectionOnClick
			/>
		</div>
	)
}
