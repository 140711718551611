import "./sidebar.css"
import { FolderOpenOutlined, StorageOutlined, GroupOutlined, DescriptionOutlined, MapOutlined, SecurityOutlined } from "@material-ui/icons";
import {HomeOutlined} from "@material-ui/icons";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function sidebar() {
	return (
		<div className="sidebar">
			<div className="sidebarWrapper">
				<div className="sidebarMenu">
					<h3 className="sidebarTitle">Dashboard</h3>
					<ul className="sidebarList">
						<a href="/"><li className="sidebarListItem">
							<HomeOutlined className="sidebarIcon"/>
							Home
						</li></a>
						<a href="/server"><li className="sidebarListItem">
							<StorageOutlined className="sidebarIcon"/>
							Server
						</li></a>
						<a href="/resources"><li className="sidebarListItem">
							<FolderOpenOutlined className="sidebarIcon"/>
							Resources
						</li></a>
						<a href="/players"><li className="sidebarListItem">
							<GroupOutlined className="sidebarIcon"/>
							Players
						</li></a>
						<a href="/logs"><li className="sidebarListItem">
							<DescriptionOutlined className="sidebarIcon"/>
							Logs
						</li></a>
						<a href="/map"><li className="sidebarListItem">
							<MapOutlined className="sidebarIcon"/>
							Map
						</li></a>
						<a href="/staff"><li className="sidebarListItem">
							<SecurityOutlined className="sidebarIcon"/>
							Staff
						</li></a>
					</ul>
				</div>
			</div>
		</div>
	)
}
