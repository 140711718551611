import Status from "../../components/status/Status"
import "./server.css"

export default function Server() {
	return (
		<div className="server">
			<div className="statusContainer">
				<Status title="Unique Players" content="100" icon="GroupOutlined" color="hotpink"/>
				<Status title="Average Player Count" content="100" icon="GroupOutlined" color="RoyalBlue"/>
				<Status title="Economy" content="$1,000,000" icon="AttachMoneyOutlined" color="yellowgreen"/>
				<Status title="Staff Online" content="5" icon="SecurityOutlined" color="GoldenRod"/>
			</div>
			<div className="statusContainer" style={{marginTop: "25px"}}>
				<Status title="Police" content="100" icon="GavelOutlined" color="red"/>
				<Status title="EMS" content="100" icon="LocalHospitalOutlined" color="blue"/>
				<Status title="Mechanic" content="5" icon="BuildOutlined" color="brown"/>
				<Status title="Car Dealer" content="5" icon="DriveEtaOutlined" color="yellow"/>
			</div>
		</div>
	)
}
