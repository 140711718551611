import "./serverbar.css"

export default function Serverbar() {
	return (
		<div className="serverbar">
			<img src="https://cdn.discordapp.com/attachments/935742439224930324/935742597203386400/trans-management.png" alt="" className="serverbarLogo" />
			<h3 className="serverbarName">Oasis Gaming</h3>
			<a className="serverbarIP" href="fivem://connect/202.165.66.101:30120">202.165.66.101:30120</a>
			<h2 className="serverbarConnected">Connected</h2>
			<div className="serverbarConnectionContainer"><span className="serverbarLastConnected">Last Connected:</span> <span className="serverbarLastTime">Now</span></div>
		</div>
	)
}
