import "./staff.css"
import * as React from 'react';
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@material-ui/data-grid';

const rows = [
	{ id: 1, username: 'Snow', charid: 1, firstname: "Indy", lastname: "Jones", job: "Police", gang: null },
	{ id: 2, username: 'Lannister', charid: 1, firstname: "Indy", lastname: "Jones", job: "Police", gang: null },
	{ id: 3, username: 'Lannister1', charid: 1, firstname: "Indy", lastname: "Jones", job: "Police", gang: null },
	{ id: 4, username: 'Stark', charid: 1, firstname: "Indy", lastname: "Jones", job: "Police", gang: null },
	{ id: 5, username: 'Targaryen', charid: 1, firstname: "Indy", lastname: "Jones", job: "Mechanic", gang: "Syndicate" },
	{ id: 6, username: 'Melisandre', charid: 2, firstname: "Indy", lastname: "Jones", job: "Police", gang: null },
	{ id: 7, username: 'Clifford', charid: 1, firstname: "Indy", lastname: "Jones", job: "Police", gang: null },
	{ id: 8, username: 'Frances', charid: 1, firstname: "Indy", lastname: "Jones", job: "Police", gang: null },
	{ id: 9, username: 'Roxie', charid: 3, firstname: "Indy", lastname: "Jones", job: "Police", gang: null  },
];

export default function Staff() {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<div className="staff">
			<DataGrid 
				rows={rows}
				columns={[
							{
								field: 'id',
								headerClassName: 'resourceHeader',
								headerName: 'ID',
								cellClassName: 'resourceHeader',
								width: 90
							},
							{
								field: 'username',
								headerName: 'Username',
								headerClassName: 'resourceHeader',
								cellClassName: 'resourceHeader',
								width: 160,
							},
							{
								field: 'charid',
								headerName: 'Charid',
								type: 'number',
								headerAlign: "left",
								align: 'left',
								headerClassName: 'resourceHeader',
								cellClassName: 'resourceHeader',
								width: 120,
							},
							{
								field: 'firstname',
								headerName: 'First Name',
								headerClassName: 'resourceHeader',
								cellClassName: 'resourceHeader',
								width: 180,
							},
							{
								field: 'lastname',
								headerName: 'Last Name',
								headerClassName: 'resourceHeader',
								cellClassName: 'resourceHeader',
								width: 180,
							},
							{
								field: 'job',
								headerName: 'Job',
								cellClassName: 'resourceHeader',
								headerClassName: 'resourceHeader',
								width: 160,
							},
							{
								field: 'gang',
								headerName: 'Gang',
								cellClassName: 'resourceHeader',
								headerClassName: 'resourceHeader',
								width: 160,
							},
							{
								field: "Functions",
								width: 490,
								headerAlign: "left",
								renderCell: (cellValues) => {
									return (
										<div className="functionbuttons">
											<Button
												variant="contained"
												color="primary"
												onClick={(event) => {
													window.location.href = "/player/" + cellValues.id;
												}}
											>
												More
											</Button>
											<Button
												variant="contained"
												color="primary"
												onClick={(event) => {
													
												}}
											>
												Discord
											</Button>
										</div>
									);
								}
							}
						]}
				pmsSize={5}
				disableSelectionOnClick
			/>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={handleClose}>Confirm</MenuItem>
				<MenuItem onClick={handleClose}>Cancel</MenuItem>
			</Menu>
		</div>
	)
}
