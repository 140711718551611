import "./logs.css"
import * as React from 'react';
import Button from "@material-ui/core/Button";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@material-ui/data-grid';

const columns = [
	{
		field: 'id',
		headerClassName: 'resourceHeader',
		headerName: 'ID',
		cellClassName: 'resourceHeader',
		width: 90
	},
	{
		field: 'timestamp',
		headerName: 'Time & Date',
		headerClassName: 'resourceHeader',
		cellClassName: 'resourceHeader',
		width: 180,
	},
	{
		field: 'resourcename',
		headerName: 'Resource Name',
		headerClassName: 'resourceHeader',
		cellClassName: 'resourceHeader',
		width: 180,
	},
	{
		field: 'type',
		headerName: 'Type',
		headerClassName: 'resourceHeader',
		cellClassName: 'resourceHeader',
		width: 180,
	},
	{
		field: 'serverclient',
		headerName: 'Server / Client',
		cellClassName: 'resourceHeader',
		headerClassName: 'resourceHeader',
		width: 170,
	},
	{
		field: 'user',
		headerName: 'User ID',
		cellClassName: 'resourceHeader',
		headerClassName: 'resourceHeader',
		width: 170,
	},
	{
		field: 'data',
		headerName: 'Data',
		cellClassName: 'resourceHeader',
		headerClassName: 'resourceHeader',
		width: 570,
	},
];

const rows = [
	{ id: 1, timestamp: "7:17 PM 6/09/2021 AEST", resourcename: 'Store Robbery', type: 'Start Robbery', serverclient: "Client", user: "1", data: "Test" },
	{ id: 2, timestamp: "7:17 PM 6/09/2021 AEST", resourcename: 'Store Robbery', type: 'Start Robbery', serverclient: "Client", user: "1", data: "Test" },
	{ id: 3, timestamp: "7:17 PM 6/09/2021 AEST", resourcename: 'Store Robbery', type: 'Start Robbery', serverclient: "Server", user: "1", data: "Test" },
	{ id: 4, timestamp: "7:17 PM 6/09/2021 AEST", resourcename: 'Store Robbery', type: 'Start Robbery', serverclient: "Client", user: "1", data: "Test" },
];

export default function Logs() {
	return (
		<div className="logs">
			<DataGrid 
				rows={rows}
				columns={columns}
				pmsSize={5}
				disableSelectionOnClick
			/>
		</div>
	)
}
