import "./playerinfo.css"

function PlayerInfo() {
	return (
		<div className="playerinfo">
			<div className="avatar">
				<img src="https://cdn.discordapp.com/attachments/634645008364077057/883596360606175272/Logo-100.jpg" alt="" className="profilePic" />
				<h3 className="discord">Jones#1913</h3>
			</div>
			<div className="characterSlots">
				<div className="character1">
					<h3 className="character1Title">Character 1 Information</h3>
					<h4 className="playerName">Indy Jones</h4>
					<div className="dob tooltip">17/09/2001
						<span class="tooltiptext">Date of Birth</span>
					</div>
					<br/>
					<div className="sex tooltip">Male
						<span class="tooltiptext">Gender</span>
					</div>
					<br/>
					<div className="job tooltip">Police - Chief
						<span class="tooltiptext">Job</span>
					</div>
					<br/>
					<div className="subjob tooltip">Syndicate - Boss
						<span class="tooltiptext">Gang</span>
					</div>
					<br/>
					<div className="bank tooltip">$ 300.00
						<span class="tooltiptext">Bank</span>
					</div>
					<br/>
					<div className="cash tooltip">$ 300.00
						<span class="tooltiptext">Cash</span>
					</div>
					<br/>
					<div className="dirtycash tooltip">$ 300.00
						<span class="tooltiptext">Dirty Cash</span>
					</div>
					<br/>
					<div className="bitcoin tooltip">฿ 300.00
						<span class="tooltiptext">Bitcoin</span>
					</div>
					<br/>
					<br/>
					<b>Inventory</b>
					<div className="inventory tooltip">
						<ul>
							<li>Pistol x 1</li>
							<li>9mm Ammo x 1000</li>
							<li>Cash x 300</li>
							<li>Heavy Armor x 1</li>
							<li>Burger x 10</li>
							<li>Water Bottle x 2</li>
						</ul>
					</div>
					<br/>
					<br/>
					<b>Houses</b>
					<div className="houses tooltip">
						<ul>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
						</ul>
					</div>
				</div>
				<div className="character2">
					<h3 className="character1Title">Character 2 Information</h3>
					<h4 className="playerName">Clive Parmer</h4>
					<div className="dob tooltip">17/09/2001
						<span class="tooltiptext">Date of Birth</span>
					</div>
					<br/>
					<div className="sex tooltip">Male
						<span class="tooltiptext">Gender</span>
					</div>
					<br/>
					<div className="job tooltip">Police - Chief
						<span class="tooltiptext">Job</span>
					</div>
					<br/>
					<div className="subjob tooltip">Syndicate - Boss
						<span class="tooltiptext">Gang</span>
					</div>
					<br/>
					<div className="bank tooltip">$ 300.00
						<span class="tooltiptext">Bank</span>
					</div>
					<br/>
					<div className="cash tooltip">$ 300.00
						<span class="tooltiptext">Cash</span>
					</div>
					<br/>
					<div className="dirtycash tooltip">$ 300.00
						<span class="tooltiptext">Dirty Cash</span>
					</div>
					<br/>
					<div className="bitcoin tooltip">฿ 300.00
						<span class="tooltiptext">Bitcoin</span>
					</div>
					<br/>
					<br/>
					<b>Inventory</b>
					<div className="inventory tooltip">
						<ul>
							<li>Pistol x 1</li>
							<li>9mm Ammo x 1000</li>
							<li>Cash x 300</li>
							<li>Heavy Armor x 1</li>
							<li>Burger x 10</li>
							<li>Water Bottle x 2</li>
						</ul>
					</div>
					<br/>
					<br/>
					<b>Houses</b>
					<div className="houses tooltip">
						<ul>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
						</ul>
					</div>
				</div>
				<div className="character3">
					<h3 className="character1Title">Character 3 Information</h3>
					<h4 className="playerName">Hayden Gay</h4>
					<div className="dob tooltip">17/09/2001
						<span class="tooltiptext">Date of Birth</span>
					</div>
					<br/>
					<div className="sex tooltip">Male
						<span class="tooltiptext">Gender</span>
					</div>
					<br/>
					<div className="job tooltip">Police - Chief
						<span class="tooltiptext">Job</span>
					</div>
					<br/>
					<div className="subjob tooltip">Syndicate - Boss
						<span class="tooltiptext">Gang</span>
					</div>
					<br/>
					<div className="bank tooltip">$ 300.00
						<span class="tooltiptext">Bank</span>
					</div>
					<br/>
					<div className="cash tooltip">$ 300.00
						<span class="tooltiptext">Cash</span>
					</div>
					<br/>
					<div className="dirtycash tooltip">$ 300.00
						<span class="tooltiptext">Dirty Cash</span>
					</div>
					<br/>
					<div className="bitcoin tooltip">฿ 300.00
						<span class="tooltiptext">Bitcoin</span>
					</div>
					<br/>
					<br/>
					<b>Inventory</b>
					<div className="inventory tooltip">
						<ul>
							<li>Pistol x 1</li>
							<li>9mm Ammo x 1000</li>
							<li>Cash x 300</li>
							<li>Heavy Armor x 1</li>
							<li>Burger x 10</li>
							<li>Water Bottle x 2</li>
						</ul>
					</div>
					<br/>
					<br/>
					<b>Houses</b>
					<div className="houses tooltip">
						<ul>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
							<li>5 Vinewood Street</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="accountInfo">
				<h3 className="accountTitle">Account Info</h3>
				<span className="discordID">266218674849841162</span>
				<br/>
				<span className="discordIDTitle">Discord ID</span>
				<br/>
				<br/>
				<span className="roleGroup">Community Leader</span>
				<br/>
				<span className="roleTitle">Role</span>
				<br/>
				<br/>
				<span className="subscriptionGroup">Patreon Tier 3</span>
				<br/>
				<span className="subscriptionTitle">Subscription Tier</span>
				<div className="hours">
					<div className="totalhours">
						<span className="totalHours">1000</span>
						<br/>
						<span className="totalHoursTitle">Total Hours</span>
					</div>
					<div className="policehours">
						<span className="totalHours">1000</span>
						<br/>
						<span className="totalHoursTitle">Police Hours</span>
					</div>
					<div className="medichours">
						<span className="totalHours">1000</span>
						<br/>
						<span className="totalHoursTitle">EMS Hours</span>
					</div>
					<div className="civhours">
						<span className="totalHours">1000</span>
						<br/>
						<span className="totalHoursTitle">Civ Hours</span>
					</div>
				</div>
				<textarea className="notes" type="text" />
				<div className="punishments">
					<div className="warns">
						<b>Warns</b>
						<ul>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
						</ul>
					</div>
					<div className="kicks">
						<b>Kicks</b>
						<ul>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
						</ul>
					</div>
					<div className="bans">
						<b>Bans</b>
						<ul>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
							<li>RDM - 13/09/2021 10:05 PM AEST</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PlayerInfo