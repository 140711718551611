import "./map.css"
import { useRef } from 'react';
import { IconButton } from '@material-ui/core';
import { Pause, PersonPinCircleOutlined } from '@material-ui/icons';
import { MapContainer, ImageOverlay, Marker, Popup, Circle } from 'react-leaflet'
import L from "leaflet";

var bounds = [[8192, 0],[0, 8192]];

export default function Map() {
	return (
		<div className="map">
			<MapContainer
				center={[667, 938.75]}
				crs={L.CRS.Simple}
				bounds={bounds}
				zoom={0}
				maxZoom={3}>
				<ImageOverlay
					url={"https://www.bragitoff.com/wp-content/uploads/2015/11/GTAV-HD-MAP-satellite.jpg"}
					bounds={[[2048, 0],[0, 2048],]}
				/>
				<Marker position={[667, 938.75]} >
					<Popup>X: 0, Y: 0</Popup>
				</Marker>
			</MapContainer>
		</div>
	)
}
// X: 0, Y: 0 == 938.75, 667
// [0, 0] = bottom left [-y, x] -y is the flipped y